import * as React from "react";
import { Button } from "@mui/material";
import "./letsc-change-the-world-frame.css";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import ContactModalThankYou from "./contact-modal-thank-you";
import ContactModal from "./contact-modal";

const LetscChangeTheWorldFrame = () => {
  const [thankyouOpen, setThankyouOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ContactModal
          setModalOpen={setModalOpen}
          setThankyouOpen={setThankyouOpen}
        />
      </Modal>
      <Modal open={thankyouOpen} onClose={() => setThankyouOpen(false)}>
        <ContactModalThankYou setThankyouOpen={setThankyouOpen} />
      </Modal>
      <div className="letsc-change-the-world-frame">
        <div className="t-e-j-apple-podcasts">
          <h1 className="god-family-then">God, family, then business.</h1>
        </div>
        <div className="t-e-j-apple-podcasts1">
          <div className="welcome-to-daves" style={{ marginBottom: "20px" }}>
            Welcome to Dave's world where he turns potential into prosperity.
            His mission? Helping you excel professionally, while never losing
            sight of what truly matters – family. He’s here to navigate your
            success story, hand-in-hand.
          </div>
        </div>
        <br />
        <div className="t-e-j-apple-podcasts2">
          <div
            className="dave-leads-evergreen"
            style={{ marginBottom: "10px" }}
          >
            Dave leads Evergreen Brands as its CEO, a company that is focused on
            creating and growing businesses that deliver an incredible customer
            experience, change the world by giving back to charities and noble
            causes, and protect the environment through sustainable practices.
          </div>
        </div>
        <div className="e-n-google-podcasts-badge">
          <Button
            className="button32"
            endIcon={
              <img width="18px" height="22px" src="/masked-icon-1.svg" />
            }
            variant="contained"
            sx={{
              fontSize: "var(--typography-body2-size)",
              color: "#fff",
              fontFamily: "var(--typography-body2)",
              textTransform: "capitalize",
              letterSpacing: "0.15px",
              lineHeight: "175%",
              fontWeight: 500,
              borderRadius: "5px",
              background: "#05182b",
              "&:hover": { background: "#05182b" },
              textTransform: "none",
            }}
            onClick={() =>
              window.open("https://www.linkedin.com/in/thedavewescott/")
            }
          >
            Connect with Dave
          </Button>
        </div>
      </div>
    </>
  );
};

export default LetscChangeTheWorldFrame;
