import * as React from "react";
import { useMemo } from "react";
import "./evergreen-brands.css";
import { navigate } from "gatsby";

const EvergreenBrands = ({
  unsplash9anj7QWy2g,
  embracingChangeTheWorld,
  evergreenBrands,
  theParentCompanyWithOneFo,
  frame291,
  propMinHeight,
  propHeight,
  href,
}) => {
  const buttonButtonsStyle = useMemo(() => {
    return {
      minHeight: propMinHeight,
    };
  }, [propMinHeight]);

  const frameIconStyle = useMemo(() => {
    return {
      height: propHeight,
    };
  }, [propHeight]);

  return (
    <div className="evergreen-brands">
      <img
        className="unsplash9anj7qwy-2g-icon"
        loading="eager"
        alt=""
        src={unsplash9anj7QWy2g}
      />
      <div className="embracing-change-frame">
        <div className="embracing-change-frame1">
          <div className="embracing-change-frame2">
            <div className="embracing-change-the">
              {embracingChangeTheWorld}
            </div>
            <div className="evergreen-brands1">{evergreenBrands}</div>
          </div>
          <div className="the-parent-company">{theParentCompanyWithOneFo}</div>
        </div>
        <div className="button-buttons" style={buttonButtonsStyle}>
          <button className="button33" onClick={() => navigate(href)}>
            <div className="base17">
              <img className="masked-icon34" alt="" src="/masked-icon.svg" />
              <div className="button34">Go there now</div>
              <img className="masked-icon35" alt="" src="/masked-icon-1.svg" />
            </div>
          </button>
          <img
            className="button-buttons-child"
            loading="eager"
            alt=""
            src={frame291}
            style={frameIconStyle}
          />
        </div>
      </div>
    </div>
  );
};

export default EvergreenBrands;
