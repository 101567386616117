import * as React from "react";
import { Button } from "@mui/material";
import "./facebook-button.css";
import { useState } from "react";
import ContactModal from "./contact-modal";
import Modal from "@mui/material/Modal";
import ContactModalThankYou from "./contact-modal-thank-you";

const FacebookButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [thankyouOpen, setThankyouOpen] = useState(false);

  return (
    <>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ContactModal
          setModalOpen={setModalOpen}
          setThankyouOpen={setThankyouOpen}
        />
      </Modal>
      <Modal open={thankyouOpen} onClose={() => setThankyouOpen(false)}>
        <ContactModalThankYou setThankyouOpen={setThankyouOpen} />
      </Modal>
      <div className="facebook-button">
        <div className="you-tube-button">
          <h1 className="lets-change-the">Let’s Change the World.</h1>
        </div>
        <div className="you-tube-button1">
          <div className="dave-balances-the">
            Dave balances the everyday momentum of his business pursuits with
            his lifelong mission: Change the World. Follow with Dave as he
            explores captivating conversations, and gain valuable insights as he
            meets others on the journey with him.
          </div>
        </div>
        <div className="tik-tok-button">
          <Button
            className="button31"
            variant="contained"
            sx={{
              width: 121,
              height: 42,

              fontSize: "var(--typography-body2-size)",
              color: "#fff",
              fontFamily: "var(--typography-body2)",
              textTransform: "capitalize",
              letterSpacing: "0.15px",
              lineHeight: "175%",
              fontWeight: 500,
              borderRadius: "5px",
              background: "#05182b",
              "&:hover": { background: "#05182b" },
              textTransform: "none",
            }}
            onClick={() => setModalOpen(true)}
          >
            Say Hello!
          </Button>
        </div>
      </div>
    </>
  );
};

export default FacebookButton;
